// Common
import React, {FunctionComponent, ReactElement, useEffect, useRef} from 'react'
import styles from '../settings.module.scss'

// Components
import TimePicker from 'components/timePicker/timePicker'
import Label from 'components/label'
import {CloseIcon, EditIcon, PlusIcon, TrashIcon} from "../../../../../assets";
import Button from "../../../../../components/button";

// Hooks
import { useCallback, useState } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// Types
import { WorkingDay, daysOfWeek } from 'types/workingDay'

const SettingsBusinessWorkingSchedule: FunctionComponent = (): ReactElement => {
  const customizeClicked = useRef<boolean>(false)
  const { control } = useFormContext()
  const { fields, insert, remove } = useFieldArray({ control, name: 'workingDays', keyName: 'key' })
  const values = useWatch({ control, name: 'workingDays' })
  const [closeDays, setCloseDays] = useState([])
  const [availableDays, setAvailableDays] = useState([])
  const [editCurrentDays, setEditCurrentDays] = useState(false)

  const [t] = useTranslation()

  useEffect(() => {
    if (workingHoursNotSetup()) return;

    setAvailableDays(values.map(o => t(`datetime.${ o.day }`) ))
    const existDays = values.map(o => o.day)
    const days = daysOfWeek.filter((day) => !existDays.includes(day) )
    setCloseDays([...new Set([...closeDays, ...days])]);
  }, [values, fields])

  const transformedStrings = (arrayStrings) => arrayStrings.map(str => str[0].toUpperCase() + str.slice(1).toLowerCase());

  const workingHoursNotSetup = () => (Array.isArray(values) && values.length === 1 && values[0]?.day === 'everyday')

  const handleRemove = useCallback((key, day) => {
    const  indexToRemove = fields.findIndex((workingDay: WorkingDay & { key: string }) => {
      return workingDay.key === key
    })

    if (indexToRemove > -1) {
      remove(indexToRemove);
    }
  }, [fields, remove])

  const handleAdd = useCallback(() => {
    const [firstRecord] = values;

    if (!firstRecord) return;

    const populateDays = (days: string[]) => {
      days.forEach((day, index) => {
        insert(index, { day, fromTime: firstRecord.fromTime, toTime: firstRecord.toTime });
      });
    };

    if (workingHoursNotSetup()) {
      remove(0);
      populateDays(daysOfWeek);
    } else {
      const existingDays = values.map((o) => o.day);
      const missingDays = daysOfWeek.filter((day) => !existingDays.includes(day));
      populateDays(missingDays);
    }
  }, [insert, values, remove])

  const shortListWorkingDays = () => {
    const workingDay = fields[0];
    if (workingDay) {
      const { key } = workingDay;
      return (
        <div className={styles['settings-group-row']} key={key}>
          <Label
            className={styles['settings-group-row-item-label-days']}
            appearance="input"
            onClick={() => setEditCurrentDays(!editCurrentDays) }
            text={transformedStrings(availableDays).join(', ')}
          />
          {['fromTime', 'toTime'].map((timeField) => (
            <div className={styles['settings-group-row-item-hours']} key={timeField} style={{ pointerEvents: "none" }}>
              <TimePicker
                control={control}
                name={`workingDays.${0}.${timeField}`}
              />
            </div>
          ))}
          <Label
            className={ styles['settings-group-row-item-label-closedays'] }
            appearance='input'
            onClick={() => setEditCurrentDays(!editCurrentDays) }
            text={ transformedStrings(closeDays).join(', ') }
          />
          <Button
            className={styles['action']}
            style={{width: '32px', padding: '6px', backgroundColor: '#BA9B5A'}}
            height={'32px'}
            onClick={() => {
              if (workingHoursNotSetup()) { handleAdd() }
              setEditCurrentDays(!editCurrentDays)
            }}
          >
            <EditIcon
              height="18"
              wight="18"
            />
          </Button>
        </div>
      );
    }
    return null;
  }


  const fullListWorkingDays = () => {
    return (
      <>
        { fields.map((workingDay: WorkingDay & { key: string }, index) =>
          <div className={ styles['settings-group-row'] } key={ workingDay.key }>
            <Label
              className={ styles['settings-group-row-item-label-days'] }
              appearance='gold-inactive'
              text={ t(`datetime.${ workingDay.day }`) }
            />
            <div className={ styles['settings-group-row-item-hours'] }>
              <TimePicker
                control={ control }
                name={ `workingDays.${ index }.fromTime` }
              />
            </div>
            <div className={ styles['settings-group-row-item-hours'] }>
              <TimePicker
                control={ control }
                name={ `workingDays.${ index }.toTime` }
              />
            </div>

            <div className={ styles['settings-group-row-item-itemActions'] }>
              <div className={ styles['settings-group-row-item-trashIcon'] }>
                <Button
                  className={styles['action']}
                  style={{width: '32px', padding: '6px', backgroundColor: '#BA9B5A'}}
                  height={'32px'}
                  onClick={() => handleRemove(workingDay.key, workingDay.day)}
                >
                  <TrashIcon/>
                </Button>
              </div>
              <div className={ styles['settings-group-row-item-itemActions-right'] }>
                { (index === 0 && fields.length < 7) && (
                  <Button
                    className={styles['action']}
                    style={{width: '32px', padding: '6px 4px 4px 4px', backgroundColor: '#BA9B5A'}}
                    height={'32px'}
                    onClick={handleAdd}
                  >
                    <PlusIcon />
                  </Button>
                )}
                {(index === 0 && editCurrentDays) && (
                  <>
                    <Button
                      className={styles['action']}
                      style={{width: '32px', padding: '6px 4px 4px 4px', backgroundColor: '#BA9B5A'}}
                      height={'32px'}
                      onClick={() => {
                        setEditCurrentDays(!editCurrentDays)
                      }}
                    >
                      <CloseIcon
                        height="14"
                        wight="14"
                      />
                    </Button>
                  </>
                )}
              </div>
            </div>

            {/*<SingleDropDown*/}
            {/*  defaultValue={[{id: `${index}-${workingDay.day}`, value: t(`datetime.${ workingDay.day }`), title:  t(`datetime.${ workingDay.day }`)}]}*/}
            {/*  options={ [{id: `${index}-${workingDay.day}`, value: t(`datetime.${ workingDay.day }`), title:  t(`datetime.${ workingDay.day }`)}] }*/}
            {/*  control={ control }*/}
            {/*  placeholder={ t(`datetime.days`) }*/}
            {/*  excludeSelectedOption={true}*/}
            {/*  name={`workingDays.${ index }.day`}*/}
            {/*/>*/}
          </div>
        ) }
      </>
    )
  }

  return (
    <>
      <div className={ styles['settings-group-row'] } key={ 'setting.hours.days.close' }>
        <Label
          text={ t('datetime.days') }
          appearance='gold-inactive'
          className={styles['settings-group-row-item-label-days']}
        />
        <Label
          text={ t('datetime.hours') }
          appearance='gold-inactive'
          className={styles['settings-group-row-item-label-hours']}
        />
        <Label
          text={ t('datetime.close') }
          appearance='gold-inactive'
          className={styles['settings-group-row-item-label-close']}
        />
      </div>

      {editCurrentDays ? fullListWorkingDays() : shortListWorkingDays()}
    </>
  )
}

export default SettingsBusinessWorkingSchedule;
