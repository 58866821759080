import { hostURL } from 'apiServices/http.service'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { Feed, FeedsFilters } from 'models/feed.model'

export class FeedsSearchByParamsService extends BaseRestInfiniteScrollService<Feed, FeedsFilters> {

  protected entityName = 'Feed'

  async buildUrl() {
    return new URL(`${ hostURL }/feed/searchByParams`)
  }

  buildItem(item: object) {
    return new Feed(item)
  }

  getItemFromResponse(response: object) {
    return response['item']
  }

  getItemsFromResponse(response: object) {
    return response['items']
  }
}
