import React, { FunctionComponent, ReactElement } from 'react'
import SettingsContactInfo from '../common/settings.contactInfo'
import Delimiter from '../../../components/delimiter/delimiter'
import styles from '../settings.module.scss'
import SettingsGenres from '../common/settings.genres'
import SettingsAboutMe from '../common/settings.aboutMe'
import SettingsPerformerGeneralInfo from './settings.performer.generalInfo'
import SettingsPerformerLocations from './settings.performer.locations'

const SettingsPerformer: FunctionComponent = (): ReactElement => {
  return (
    <>
      <SettingsPerformerGeneralInfo />

      <Delimiter className={ styles['delimiter'] } />

      {/*<SettingsPerformerLocations />*/}

      {/*<Delimiter className={ styles['delimiter'] } />*/}

      <SettingsContactInfo />

      <Delimiter className={ styles['delimiter'] } />

      {/*<SettingsGenres />*/}

      {/*<Delimiter className={ styles['delimiter'] } />*/}

      <SettingsAboutMe />
    </>
  )
}

export default SettingsPerformer;
