import React, { FunctionComponent, ReactElement } from "react";
import Delimiter from "../../../components/delimiter/delimiter";
import styles from "../settings.module.scss";
import SettingsContactInfo from "../common/settings.contactInfo";
import SettingsAboutMe from "../common/settings.aboutMe";
import SettingsGenres from "../common/settings.genres";
import SettingsFunGeneralInfo from "./settings.fun.generalInfo";


const SettingsFun: FunctionComponent = (): ReactElement => {
  return (
    <>
      <SettingsFunGeneralInfo />

      <Delimiter className={ styles['delimiter'] } />

      <SettingsContactInfo />

      {/*<Delimiter className={ styles['delimiter'] } />*/}

      {/*<SettingsGenres />*/}

      <Delimiter className={ styles['delimiter'] } />

      <SettingsAboutMe />
    </>
  )
}

export default SettingsFun;
