// Common
import React, { FunctionComponent, ReactElement } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "../settings.module.scss";

// Components
import Input from 'components/input'

const SettingsFunGeneralInfo: FunctionComponent = (): ReactElement => {
  const { control } = useFormContext()
  const [t] = useTranslation();

  return (
    <div className={ styles['settings-group'] }>
      <div className={ styles['settings-group-row'] }>
        <Controller
          name="nickName"
          control={control}
          render={({field}) => (
            <Input
              {...field}
              className={ styles['settings-group-row-item'] }
              control={ control }
              name='nickName'
              title={ t('titles.user_name') }
              placeholder={ t('placeholders.enter_user_name') }
              required={ true }
              theme='dark'
            />
          )}
        />

        <Controller
          name="profileName"
          control={control}
          render={({field}) => (
            <Input
              className={ styles['settings-group-row-item'] }
              control={ control }
              name='profileName'
              title={ t('titles.full_name') }
              placeholder={ t('placeholders.enter_full_name') }
              required={ true }
              theme='dark'
            />
          )}
        />
      </div>
    </div>
  )
}

export default SettingsFunGeneralInfo;
