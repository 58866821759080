import { combineReducers, Reducer } from 'redux'
import generateCRUDReducer from 'redux/helpers/generateCRUDReducer'

import profileReducer, { State as ProfileState } from './profile.reducer'
import spinnerReducer from './spinner.reducer'
import authenticationReducer, { State as AuthenticationState } from './authentication.reducer'
import rolesReducer, { State as RolesState } from './roles.reducer'
import genresReducer, { State as GenresState } from './genres.reducer'
import faqReducer, { State as FAQState } from './faq.reducer'
import languageReducer, { State as LanguageState } from './language.reducer'
import aboutUsReducer, { State as AboutUsState } from './aboutUs.reducer'
import contactUsReducer, { State as ContactUsState } from './contactUs.reducer'

// ADMIN
import adminAuthReducer from './admin/auth'
import adminPolicyReducer from './admin/policy.reducer'

import { Photo, PhotoFilters } from 'models/photo.model'
import { Event, EventsFilters } from 'models/event.model'
import { Feed, FeedsFilters } from 'models/feed.model'
import { PerformerUser } from 'models/performerUser.model'
import { Track, TracksFilters } from 'models/track.model'
import { User, UsersFilters } from 'models/user.model'
import { Location } from 'models/location.model'
import { CountriesFilters, Country } from 'models/country.model'
import { Filters, State } from 'types/reduxCRUD'

import calendarEventsActionTypes from '../action-types/calendar-events'
import calendarMonthEventsActionTypes from '../action-types/calendar-month-events'
import communityActionTypes from '../action-types/community'
import countriesActionTypes from '../action-types/countries'
import followersActionTypes from '../action-types/followers'
import friendsActionTypes from '../action-types/friends'
import friendshipsActionTypes from '../action-types/friendships'
import photosActionTypes from '../action-types/photos'
import feedsActionTypes from '../action-types/feeds'
import photosModalActionTypes from '../action-types/photos-modal'
import playerActionTypes from '../action-types/player'
import profileLocationsActionTypes from '../action-types/profileLocations'
import usersActionTypes from '../action-types/users'
import { blackListActionTypes, funsListActionTypes, friendsListActionTypes } from '../action-types/black-list-friends'
import { eventPerformersActionTypes } from '../action-types/events'

export type RootReducerState = {
  aboutUs: Partial<AboutUsState>
  authentication: Partial<AuthenticationState>
  blackList: State<User>
  calendarEvents: State<Event>
  calendarMonthEvents: State<Event>
  community: State<User>
  contactUs: Partial<ContactUsState>
  countries: State<Country>
  eventPerformers: State<PerformerUser>
  faq: Partial<FAQState>
  friends: State<User>
  friendsList: State<PerformerUser>
  friendships: State<User>
  funsList: State<PerformerUser>
  languages: Partial<LanguageState>
  photos: State<Photo>
  feeds: State<Feed>
  photosModal: State<Photo>
  player: State<Track>
  profile: { profile: Partial<ProfileState>, locations: State<Location> }
  roles: Partial<RolesState>
  genres: Partial<GenresState>
  users: State<User>
  admin: { auth: any, policy: any }
}

export const rootReducer: Reducer<RootReducerState> = combineReducers({
  aboutUs: aboutUsReducer,
  authentication: authenticationReducer,
  blackList: generateCRUDReducer<User, UsersFilters>(blackListActionTypes),
  calendarEvents: generateCRUDReducer<Event, EventsFilters>(calendarEventsActionTypes),
  calendarMonthEvents: generateCRUDReducer<Event, EventsFilters>(calendarMonthEventsActionTypes),
  community: generateCRUDReducer<User, UsersFilters>(communityActionTypes),
  contactUs: contactUsReducer,
  countries: generateCRUDReducer<Country, CountriesFilters>(countriesActionTypes),
  eventPerformers: generateCRUDReducer<PerformerUser, UsersFilters>(eventPerformersActionTypes),
  faq: faqReducer,
  genres: genresReducer,
  followers: generateCRUDReducer<User, UsersFilters>(followersActionTypes),
  friends: generateCRUDReducer<User, UsersFilters>(friendsActionTypes),
  friendsList: generateCRUDReducer<PerformerUser, UsersFilters>(friendsListActionTypes),
  friendships: generateCRUDReducer<User, UsersFilters>(friendshipsActionTypes),
  funsList: generateCRUDReducer<PerformerUser, UsersFilters>(funsListActionTypes),
  languages: languageReducer,
  feeds: generateCRUDReducer<Feed, FeedsFilters>(feedsActionTypes),
  photos: generateCRUDReducer<Photo, PhotoFilters>(photosActionTypes),
  photosModal: generateCRUDReducer<Photo, PhotoFilters>(photosModalActionTypes),
  player: generateCRUDReducer<Track, TracksFilters>(playerActionTypes),
  profile: combineReducers({
    profile: profileReducer,
    locations: generateCRUDReducer<Location, Filters>(profileLocationsActionTypes)
  }),
  roles: rolesReducer,
  spinner: spinnerReducer,
  users: generateCRUDReducer<User, UsersFilters>(usersActionTypes),
  admin: combineReducers({ auth: adminAuthReducer, policy: adminPolicyReducer })
})
