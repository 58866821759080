// Common
import React, { FunctionComponent, ReactElement } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { format } from 'date-fns'

// Components
import Image from 'components/image'

// Types
import { Feed as FeedModel } from 'models/feed.model'

interface IComponentProps {
  feed: FeedModel;
  className?: string;
  showFullComponent?: boolean;
}

export const PhotoCardComponent: FunctionComponent<IComponentProps> = ({ feed,
                                                                         className,
                                                                         showFullComponent
                                                                       }): ReactElement => {
  return (
    <div className={ styles['feed-data'] }>
      <div className={ styles['feed-data-container'] }>
        <div className={ styles['feed-user-logo'] }>
          <Image
            className={ styles['feed-user-logo-image'] }
            src={ feed.user.avatarUrl }
          />
          <div className={ styles['feed-user-info'] }>
                <span className={ styles['feed-user-profile-name'] } >
                  { feed.user.profileName }
                </span>
            <span className={ styles['feed-user-profile-last-active'] } >
                  12 min ago
            </span>
          </div>
        </div>
        <div className={ styles['feed-data-container-info'] }>
          <p className={ styles['feed-data-container-location'] }>{ feed.entityType }</p>
        </div>
        <p className={ styles['feed-data-container-description'] }>{ feed?.description }</p>
        <Image
          className={ styles['feed-image'] }
          src={ feed.imageUrl }
        />
      </div>
    </div>
  )
}

export default PhotoCardComponent
