// Common
import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import AlbumCardComponent from '../feedCard/AlbumCard'
import EventCardComponent from '../feedCard/EventCard'
import UserCardComponent from '../feedCard/UserCard'
import PhotoCardComponent from '../feedCard/PhotoCard'
import PostCardComponent from '../feedCard/PostCard'
import TrackCardComponent from '../feedCard/TrackCard'
import DefaultFeedCardComponent from '../feedCard/Defaultcard'

// Hooks
import { useTranslation } from 'react-i18next'
import { useInject } from 'hooks/useInject'

// Services
import { FeedsService } from 'services/feeds.service'
import { useParams } from 'react-router'
import PageTabsHeader from '../../../../../components/pageTabsHeader'
import Delimiter from '../../../components/delimiter/delimiter'
import { useSubscribeValue } from '../../../../../hooks/useSubscribeValue'

const entityComponents = {
  Album: AlbumCardComponent,
  Event: EventCardComponent,
  Photo: PhotoCardComponent,
  Post: PostCardComponent,
  Track: TrackCardComponent,
  User: UserCardComponent,
}

export const FeedComponent: FunctionComponent = (): ReactElement => {
  const { feedId } = useParams()

  const [t] = useTranslation()

  const feedsService = useInject(FeedsService)
  const feed = useSubscribeValue(feedsService.item)

  const Component = entityComponents[feed?.entityType]

  useEffect(() => {
    feedsService.requestOne(parseInt(feedId))
  }, [feedsService, feedId])

  return (
    <>
      <PageTabsHeader
        backLabel={ t('buttons.back') }
      />
      <Delimiter className={ styles['delimiter'] } />
      <div
        className={ cn( styles['feed'] ) }
      >
        { Component ? (
          <Component
            feed={ feed }
            className={ feed?.entityType }
          />
        ) : (
          <DefaultFeedCardComponent feed={ feed } className={ feed?.entityType } />
        ) }
      </div>
    </>
  )
}

export default FeedComponent
