import React, { createContext, useContext, useState, useCallback, useMemo, ReactNode, FunctionComponent } from 'react'
import cn from 'classnames'
import styles from './feedHeader.module.scss'
import { EntityType } from 'types/entityType'

import { useFeedFilters } from './contexts/FeedFiltersContext'

const FeedListHeader: FunctionComponent = () => {
  const { feedFilters, setFeedFilters } = useFeedFilters()

  const entityTypesArray = Object.entries(EntityType).map(([key, value]) => ({
    key,
    value,
  }))

  const handleAddEntityFilters = useCallback(
    (value: string) => {
      setFeedFilters((prevFilters) =>
        prevFilters.includes(value) ? prevFilters.filter((filter) => filter !== value) : [...prevFilters, value]
      )
    }, [feedFilters, setFeedFilters]
  )

  return (
    <div className={ cn(styles['header'], styles['feed-list-entities']) }>
      { entityTypesArray.map((entity) => (
        <span
          key={ entity.key }
          className={ cn(
            styles['header'],
            styles['feed-list-entity-item'],
            { [styles['feed-list-entity-item-active']]: feedFilters.includes(entity.value) }
          ) }
          onClick={ () => handleAddEntityFilters(entity.value) }
        >
          { entity.value }
        </span>
      )) }
    </div>
  )
}

FeedListHeader.displayName = 'FeedListHeader'
export default FeedListHeader
