import React, {FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState} from 'react'
import cn from 'classnames'
import styles from './feedHeader.module.scss'

// Components
import Input from 'components/input'
import Icon from 'components/icon'
import Button from 'components/button'

// Hooks
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useFeedSearchFilters } from './contexts/FeedSearchFiltersContext'
// Types
import {Post, Post as PostModel} from 'models/post.model'
import {Feed, Feed as FeedModel} from 'models/feed.model'

import Modal from 'components/modal'
import PostForm from "../../blog/postForm";

interface IComponentProps {
  post?: PostModel,
  feed?: FeedModel,
  onClose?: () => void,
}

const FeedSearchHeader: FunctionComponent<IComponentProps> = ({
                                                                post = new Post(),
                                                                feed = new Feed(),
                                                                onClose
}): ReactElement => {
  const [t] = useTranslation()

  const { control, handleSubmit } = useForm();

  const { searchQuery, setSearchQuery } = useFeedSearchFilters()
  const [formOpen, setFormOpen] = useState(false)

  const inputSearchRef = useRef(null);

  const handleOpenForm = useCallback((opened: boolean) => () => {
    setFormOpen(opened)
  }, [])

  const handleAddEntityFilters = useCallback(
    (value: string) => { setSearchQuery(value)
    }, [searchQuery, setSearchQuery]
  )


  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // TODO catch input value !!! need at this place
      if (event.key === 'Enter') {
        // console.log(inputSearchRef, '-------')
        // handleAddEntityFilters(searchQuery)
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className={ cn(styles['header']) }>
      <form
        className={ styles['form'] }
        onSubmit={ (e) => {
          e.preventDefault()
        } }
      >
        <div className={ styles['header-search-container'] }>
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <Input
                control={control}
                {...field}
                required
                ref={inputSearchRef}
                theme="dark"
                className={styles['settings-group-row-item']}
                rightIcon={
                  <Icon
                    name="search"
                    width={20}
                    height={20}
                    size={16}
                    onClick={() => {
                      handleAddEntityFilters(field.value)
                    }}
                  />
                }
              />
            )}
          />
        </div>
      </form>
      <Button appearance="gold" onClick={ handleOpenForm(true) }>
        { t('buttons.post_add') }
      </Button>
      <Modal
        isOpen={ formOpen }
        onClose={ handleOpenForm(false) }
      >
        <PostForm
          onClose={ handleOpenForm(false) }
        />
      </Modal>
    </div>
  )
}

FeedSearchHeader.displayName = 'FeedSearchHeader'
export default FeedSearchHeader
