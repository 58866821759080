import {object, date, InferType, array} from 'yup'
import i18n from 'i18n'
import { Attachment } from 'types/attachment'
import { hostURL } from 'apiServices/http.service'
import { User } from './user.model'
import {EntityType} from "types/entityType";

const feedSchema = object({
  // fromTime: date().required(() => i18n.t('validations.is_required')),
  // Time: date().required(() => i18n.t('validations.is_required')),
})

export class Feed implements InferType<typeof feedSchema> {
  id: number
  user: User
  from: Date
  to: Date
  entityId: number
  entityType: EntityType
  title: string
  genres: any[]
  location: any
  description: string
  image: Attachment
  imageUrl: string
  createdAt: Date

  constructor(data?) {
    this.id = data?.id
    this.user = data?.user && new User(data.user)
    this.from = new Date(data?.from || '')
    this.to = data?.toTime ? new Date(data?.to) : new Date()
    this.entityId = data?.entityId
    this.entityType = data?.entityType
    this.title = data?.title
    this.location = {
      city: data?.city || '',
      country: data?.country || '',
      address: data?.address || ''
    }
    this.description = data?.description
    this.genres = data?.genres || ['Pop', 'Electro', 'A&B']

    if (data?.image) {
      this.image = data.image
    } else {
      this.image = {
        fileName: data?.imageFileName,
        fileSize: data?.imageFileSize,
        fileType: data?.imageFileType,
        id: data?.id,
        temp: false,
      }
    }

    this.imageUrl = `${ hostURL }/attachments/${ this.user?.id }/${ this.id }/${ this.id }/${ this.image.fileName }`
  }

  asForm() {
    return {
      defaultValues: {

      }
    }
  }
}

export interface FeedsFilters {
  page?: number,
  perPage: number,
  search?: string,
  entityTypes?: string[],
  from?: Date,
  to?: Date

  // TODO not implemented yet
  //  sort
  sortColumn?: keyof User
  sortType?: 'asc' | 'desc'
}
