import React, { createContext, useContext, useState, useMemo, ReactNode, FunctionComponent } from 'react'

interface FeedSearchContextType {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const FeedSearchContext = createContext<FeedSearchContextType | undefined>(undefined)

// Custom Hook for consuming context
export const useFeedSearchFilters = () => {
  const context = useContext(FeedSearchContext)
  if (!context) {
    throw new Error('useFeedSearchFilters must be used within a FeedSearchFiltersProvider')
  }
  return context
}

export const FeedSearchFiltersProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const value = useMemo(() => ({ searchQuery, setSearchQuery }), [searchQuery])

  return <FeedSearchContext.Provider value={ value }>{ children }</FeedSearchContext.Provider>
}
