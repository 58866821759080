import React, { FunctionComponent, ReactElement, memo } from "react";
import cn from 'classnames';
import styles from './businessInfo.module.scss';

// Hooks
import { useTranslation } from "react-i18next";

// Types
import { GeneralLocation } from 'types/generalLocation'

interface IComponentProps {
  generalLocation: GeneralLocation
  workingHours?: unknown[]
  className?: string
}

const BusinessInfo: FunctionComponent<IComponentProps> = ({
  generalLocation,
  workingHours,
  className
}): ReactElement => {
  const [t] = useTranslation()

  return (
      <div className={ cn(styles['business-info'], className) }>
        <div className={ styles['business-info-group'] }>
          <span className={ styles['business-info-group-title'] }>{ t('headers.location') }</span>
          <span className={ styles['business-info-group-item-location'] }>
            {generalLocation?.address || ''}
          </span>
        </div>

        <div className={ styles['business-info-group'] }>
          <span>{ t('headers.hours') }</span>
          <span className={ styles['business-info-group-item'] }>
            <span className={ styles['business-info-group-item-workingHours'] }>
              {workingHours.length > 0 && (
                workingHours.map((day, index) => (
                  <div key={index} className={styles['business-info-group-item-workingHours']}>
                    <span key={index} className={ styles['business-info-group-item-workingHours-day'] }>{day["day"]}</span>
                    <span className={ styles['business-info-group-item-workingHours-time'] }>
                      <span key={index} className={ styles['business-info-group-item-workingHours-time-from'] }>{day["fromTime"]}</span>
                      <span key={index} className={ styles['business-info-group-item-workingHours-time-to'] }>{day["toTime"]}</span>
                    </span>
                  </div>
                ))
              )}
            </span>
          </span>
        </div>
      </div>
  )
}

export default memo(BusinessInfo);
