import { Role } from 'types/role'
import { Location } from './location.model'
import { WorkingDay } from './workingDay.model'
import { Filters } from '../types/reduxCRUD'
import { GeneralLocation } from '../types/generalLocation'
import { hostURL } from '../apiServices/http.service'
import { Attachment } from '../types/attachment'
import { daysOfWeek } from '../types/workingDay'

export class User {
  aboutMe: string
  avatar: Attachment
  avatarUrl: string
  bio: string
  coverImage: Attachment
  coverImagePreview: string
  coverImageUrl: string
  email: string
  friendsCount: number
  funsCount: number
  generalLocation: GeneralLocation
  genres: { title: string }[]
  id: number
  links?: string[]
  locations?: Location[]
  nickName: string
  phoneNumber?: number
  privateParty?: boolean
  profileName?: string
  raw: string
  role: Role
  rolw?: Role
  showEmail: boolean
  showPhoneNumber: boolean
  verified: boolean
  weddingParty?: boolean
  workingDays?: WorkingDay[]

  friendshipInitiatorId?: number
  friendshipStatus?: string
  follower?: boolean
  following?: boolean

  constructor(raw: any) {
    this.aboutMe = raw?.bio || ''
    this.bio = raw?.bio || ''
    this.avatar = raw?.avatar
    this.coverImage = raw?.coverImage
    this.nickName = raw.nickName
    this.email = raw.email
    this.friendsCount = raw?.friendsCount
    this.funsCount = raw?.funsCount
    this.generalLocation = raw?.generalLocation || {}
    this.genres = raw?.genres || []
    this.id = raw.id
    this.links = raw?.links || []
    this.phoneNumber = raw?.phoneNumber
    this.privateParty = raw?.privateParty
    this.profileName = raw?.profileName
    this.role = raw?.role
    this.showEmail = raw?.showEmail
    this.showPhoneNumber = raw?.showPhoneNumber
    this.verified = !!raw?.verified
    this.weddingParty = raw?.weddingParty
    this.workingDays = raw?.workingDays || []

    if (raw?.coverImage) {
      this.coverImage = raw.coverImage
    } else if (raw.coverImageFileName) {
      this.coverImage = {
        fileName: raw?.coverImageFileName,
        fileSize: raw?.coverImageFileSize,
        fileType: raw?.coverImageFileType,
        id: raw?.id,
        temp: false,
      }
    }

    this.coverImageUrl = this.coverImage && `${ hostURL }/attachments/${ this.id }/coverImage/coverImage/${ this.coverImage.fileName }`

    if (raw?.avatar) {
      this.avatar = raw.avatar
    } else if (raw.avatarFileName) {
      this.avatar = {
        fileName: raw?.avatarFileName,
        fileSize: raw?.avatarFileSize,
        fileType: raw?.avatarFileType,
        id: raw?.id,
        temp: false,
      }
    }

    this.avatarUrl = this.avatar && `${ hostURL }/attachments/${ this.id }/avatar/avatar/${ this.avatar.fileName }`

    this.friendshipInitiatorId = raw?.friendshipInitiatorId
    this.friendshipStatus = raw?.friendshipStatus
    this.follower = raw?.follower
    this.following = raw?.following
  }

  public asForm() {
    return {
      id: this.id,
      email: this.email,
      profileName: this.profileName,
      bio: this.bio,
      phoneNumber: this.phoneNumber,
      nickName: this.nickName,
      workingDays: (this.workingDays.length && this.workingDays) || [{ day: 'everyday', fromTime: null, toTime: null }],
      generalLocation: this.generalLocation,
      genres: this.genres,
      role: this.role,
      links: ((this.links.length && this.links) || ['']).map(link => ({ value: link })),
    }
  }

  public asPayloadJSON(): string {
    return JSON.stringify({
      id: this.id,
      email: this.email,
      profile_name: this.profileName,
      bio: this.bio,
      phone_number: this.phoneNumber,
      working_hours: this.workingDays,
      private_party: this.privateParty,
      wedding_party: this.weddingParty,
      genres: this.genres,
      links: this.links,
      avatar: this.avatar,
      cover: this.coverImage,
    })
  }

  public static asPayloadJson(params) {
    const body = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== undefined)
    )

    if ('generalLocation' in params) {
      Object.entries(params.generalLocation).forEach(([key, val]) => body[key] = val)
      delete body.generalLocation
    }
    if ('links' in params) { body['links'] = params.links.map(link => link.value) }
    if ('workingDays' in params) {
      if (params.workingDays.length === 1) {
        const [record] = params.workingDays
        body['workingDays'] = daysOfWeek.map(day => ({ day, fromTime: record.fromTime, toTime: record.toTime }))
      }
    }
    if ('avatar' in params) {
      body['avatarId'] = params.avatar.id.toString()
      body['avatarFileSize'] = params.avatar.fileSize
      body['avatarFileName'] = params.avatar.fileName
      body['avatarFileType'] = params.avatar.fileType
      body['avatarTemp'] = params.avatar.temp
    }
    if ('coverImage' in params) {
      body['coverImageId'] = params.coverImage.id.toString()
      body['coverImageFileSize'] = params.coverImage.fileSize
      body['coverImageFileName'] = params.coverImage.fileName
      body['coverImageFileType'] = params.coverImage.fileType
      body['coverImageTemp'] = params.coverImage.temp
    }

    return body
  }
}

export interface UsersFilters extends Filters {
  name?: string
  role?: Role
  page?: number
  perPage?: number
  friends?: boolean
  followers?: boolean
  followings?: boolean

  //  sort
  sortColumn?: keyof User
  sortType?: 'asc' | 'desc'
  userId?: number | string
}
