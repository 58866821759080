import { all } from 'redux-saga/effects'
import generateCRUDSaga from 'redux/helpers/generateCRUDSaga'
import { Filters } from '../../types/reduxCRUD'

import profileSaga from './profile.saga'
import authenticationSaga from './authentication.saga'
import rolesSaga from './roles.saga'
import faqSaga from './faq.saga'
import genreSaga from './genres.saga'
import languageSaga from './language.saga'
import contactUsSaga from './contactUs.saga'
import aboutUsSaga from './aboutUs.saga'

// ADMIN
import adminAuthSaga from './admin/auth.saga'
import adminPolicySaga from './admin/policy.saga'

import { Photo, PhotoFilters } from 'models/photo.model'
import { Event, EventsFilters } from 'models/event.model'
import { Feed, FeedsFilters } from 'models/feed.model'
import { Track, TracksFilters } from 'models/track.model'
import { User, UsersFilters } from 'models/user.model'
import { PerformerUser, EventPerformersFilters } from 'models/performerUser.model'
import { Location } from 'models/location.model'
import { Country, CountriesFilters } from 'models/country.model'

// Action Types
import calendarEventsActionTypes from '../action-types/calendar-events'
import calendarMonthEventsActionTypes from '../action-types/calendar-month-events'
import communityActionTypes from '../action-types/community'
import countriesActionTypes from '../action-types/countries'
import followersActionTypes from '../action-types/followers'
import friendsActionTypes from '../action-types/friends'
import friendshipsActionTypes from '../action-types/friendships'
import photosActionTypes from '../action-types/photos'
import feedsActionTypes from '../action-types/feeds'
import photosModalActionTypes from '../action-types/photos-modal'
import playerActionTypes from '../action-types/player'
import profileLocationsActionTypes from '../action-types/profileLocations'
import usersActionTypes from '../action-types/users'
import { blackListActionTypes, funsListActionTypes, friendsListActionTypes } from '../action-types/black-list-friends'
import { eventPerformersActionTypes } from '../action-types/events'

// Services
import countriesService from 'apiServices/countries.service'
import followersService from 'apiServices/followers.service'
import friends2Service from 'apiServices/friends.service'
import friendshipsService from 'apiServices/friendships.service'
import photosService from 'apiServices/photos.service'
import feedsService from 'apiServices/feeds.service'
import tracksService from 'apiServices/tracks.service'
import usersService from 'apiServices/users.service'
import { blackListService, funsService, friendsService } from 'apiServices/blackListFriends.service'
import { eventsService, eventPerformersService } from 'apiServices/events.service'
import { profileLocationsService } from 'apiServices/profile.service'

// Actions
import calendarEventsActions from '../actions/calendar-events.action-creators'
import calendarMonthEventsActions from '../actions/calendar-month-events.action-creators'
import communityActions from '../actions/community.action-creators'
import countriesActions from '../actions/countries.action-creators'
import followersActions from '../actions/followers.action-creators'
import friendsActions from '../actions/friends.action-creators'
import friendshipsActions from '../actions/friendships.action-creators'
import photosActions from '../actions/photos.action-creators'
import feedsActions from '../actions/feeds.action-creators'
import photosModalActions from '../actions/photos-modal.action-creators'
import playerActions from '../actions/player.action-creators'
import profileLocationsActions from '../actions/profileLocations.action-creators'
import usersActions from '../actions/users.action-creators'
import { eventPerformersActions } from '../actions/events.action-creators'
import { blackListActions, funsListActions, friendsListActions } from '../actions/black-list-friends.action-creator'

const photosSaga = generateCRUDSaga<Photo, PhotoFilters>({
  at: photosActionTypes,
  service: photosService,
  actions: photosActions,
  entityConstructor: i => new Photo(i),
  reducerName: 'photos',
  apiItemsKey: 'photos',
  apiItemKey: 'photo',
  entityName: 'Photo',
  paginationStrategy: 'infinite-scroll',
})

const photosModalSaga = generateCRUDSaga<Photo, PhotoFilters>({
  at: photosModalActionTypes,
  service: photosService,
  actions: photosModalActions,
  entityConstructor: i => new Photo(i),
  reducerName: 'photosModal',
  apiItemsKey: 'photos',
  apiItemKey: 'photo',
  entityName: 'Photo',
  paginationStrategy: 'pages'
})

const playerSaga = generateCRUDSaga<Track, TracksFilters>({
  at: playerActionTypes,
  service: tracksService,
  actions: playerActions,
  entityConstructor: i => new Track(i),
  reducerName: 'player',
  apiItemsKey: 'tracks',
  apiItemKey: 'track',
  entityName: 'Track',
  paginationStrategy: 'pages'
})

const eventPerformersSaga = generateCRUDSaga<PerformerUser, EventPerformersFilters>({
  at: eventPerformersActionTypes,
  service: eventPerformersService,
  actions: eventPerformersActions,
  entityConstructor: i => new PerformerUser(i),
  reducerName: 'eventPerformers',
  apiItemsKey: 'items',
  apiItemKey: 'item',
  entityName: 'Performer',
  paginationStrategy: 'all'
})

const calendarEventsSaga = generateCRUDSaga<Event, EventsFilters>({
  at: calendarEventsActionTypes,
  service: eventsService,
  actions: calendarEventsActions,
  entityConstructor: i => new Event(i),
  reducerName: 'calendarEvents',
  apiItemsKey: 'events',
  apiItemKey: 'event',
  entityName: 'Event',
  paginationStrategy: 'all'
})

const calendarMonthEventsSaga = generateCRUDSaga<Event, EventsFilters>({
  at: calendarMonthEventsActionTypes,
  service: eventsService,
  actions: calendarMonthEventsActions,
  entityConstructor: i => new Event(i),
  reducerName: 'calendarMonthEvents',
  apiItemsKey: 'events',
  apiItemKey: 'event',
  entityName: 'Event',
  paginationStrategy: 'all'
})

const blackListSaga = generateCRUDSaga<User, UsersFilters>({
  at: blackListActionTypes,
  service: blackListService,
  actions: blackListActions,
  entityConstructor: i => new User(i),
  reducerName: 'blackList',
  apiItemsKey: 'black_lists',
  apiItemKey: null,
  entityName: 'Black list',
  paginationStrategy: 'infinite-scroll'
})
const funsListSaga = generateCRUDSaga<User, UsersFilters>({
  at: funsListActionTypes,
  service: funsService,
  actions: funsListActions,
  entityConstructor: i => new User(i),
  reducerName: 'funsList',
  apiItemsKey: 'funs',
  apiItemKey: null,
  entityName: 'Fun',
  paginationStrategy: 'infinite-scroll'
})
const friendsListSaga = generateCRUDSaga<User, UsersFilters>({
  at: friendsListActionTypes,
  service: friendsService,
  actions: friendsListActions,
  entityConstructor: i => new User(i),
  reducerName: 'friendsList',
  apiItemsKey: 'subscribed',
  apiItemKey: null,
  entityName: 'Friend',
  paginationStrategy: 'infinite-scroll'
})

const usersSaga = generateCRUDSaga<User, UsersFilters>({
  at: usersActionTypes,
  service: usersService,
  actions: usersActions,
  entityConstructor: i => new User(i),
  reducerName: 'users',
  apiItemsKey: 'users',
  apiItemKey: 'item',
  entityName: 'User',
  paginationStrategy: 'infinite-scroll'
})

const communitySaga = generateCRUDSaga<User, UsersFilters>({
  at: communityActionTypes,
  service: usersService,
  actions: communityActions,
  entityConstructor: i => new User(i),
  reducerName: 'community',
  apiItemsKey: 'users',
  apiItemKey: 'user',
  entityName: 'User',
  paginationStrategy: 'infinite-scroll'
})

const friendsSaga = generateCRUDSaga<User, UsersFilters>({
  at: friendsActionTypes,
  service: friends2Service,
  actions: friendsActions,
  entityConstructor: i => new User(i),
  reducerName: 'friends',
  apiItemsKey: 'n/a',
  apiItemKey: 'n/a',
  entityName: 'n/a',
  paginationStrategy: 'all'
})

const friendshipsSaga = generateCRUDSaga<User, UsersFilters>({
  at: friendshipsActionTypes,
  service: friendshipsService,
  actions: friendshipsActions,
  entityConstructor: i => new User(i),
  reducerName: 'friendships',
  apiItemsKey: 'n/a',
  apiItemKey: 'n/a',
  entityName: 'n/a',
  paginationStrategy: 'all'
})

const followersSaga = generateCRUDSaga<User, UsersFilters>({
  at: followersActionTypes,
  service: followersService,
  actions: followersActions,
  entityConstructor: i => new User(i),
  reducerName: 'followers',
  apiItemsKey: 'n/a',
  apiItemKey: 'n/a',
  entityName: 'n/a',
  paginationStrategy: 'all'
})

const profileLocationsSaga = generateCRUDSaga<Location, Filters>({
  at: profileLocationsActionTypes,
  service: profileLocationsService,
  actions: profileLocationsActions,
  entityConstructor: i => new Location(i),
  reducerName: 'profile.locations',
  apiItemsKey: 'current_locations',
  apiItemKey: 'current_location',
  entityName: 'Current location',
  paginationStrategy: 'all'
})

const countriesSaga = generateCRUDSaga<Country, CountriesFilters>({
  at: countriesActionTypes,
  service: countriesService,
  actions: countriesActions,
  entityConstructor: i => new Country(i),
  reducerName: 'countries',
  apiItemsKey: 'countries',
  apiItemKey: 'country',
  entityName: 'Country',
  paginationStrategy: 'all'
})

const feedsSaga = generateCRUDSaga<Feed, FeedsFilters>({
  at: feedsActionTypes,
  service: feedsService,
  actions: feedsActions,
  entityConstructor: i => new Feed(i),
  reducerName: 'feeds',
  apiItemsKey: 'feeds',
  apiItemKey: 'feed',
  entityName: 'Feed',
  paginationStrategy: 'infinite-scroll'
})

export default function* rootSaga() {
  yield all([
    aboutUsSaga(),
    authenticationSaga(),
    blackListSaga(),
    calendarEventsSaga(),
    calendarMonthEventsSaga(),
    communitySaga(),
    contactUsSaga(),
    countriesSaga(),
    feedsSaga(),
    eventPerformersSaga(),
    faqSaga(),
    genreSaga(),
    followersSaga(),
    friendsListSaga(),
    friendsSaga(),
    friendshipsSaga(),
    funsListSaga(),
    languageSaga(),
    photosModalSaga(),
    photosSaga(),
    playerSaga(),
    profileLocationsSaga(),
    profileSaga(),
    rolesSaga(),
    usersSaga(),

    // Admin
    adminAuthSaga(),
    adminPolicySaga()
  ])
}
