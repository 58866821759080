import React, {FunctionComponent, ReactElement} from "react";
import styles from "../settings.module.scss";

// Hooks
import {useFormContext, Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

// Components
import Label from 'components/label'
import Textarea from 'components/textarea'

const SettingsAboutMe: FunctionComponent = (): ReactElement => {
  const {control} = useFormContext();
  const [t] = useTranslation();

  return (
    <>
      <div className={styles['settings-group-row']}>
        <div className={styles['settings-group-row-full-width']}>
          <Label
            className={styles['label']}
            appearance='input'
            text={t('headers.general_info')}
          />

          <Controller
            name="bio"
            control={control}
            render={({field}) => (
              <Textarea
                {...field}
                control={control}
                name='bio'
                rows={3}
              />
            )}
          />

        </div>
      </div>
    </>
  )
}

export default SettingsAboutMe;
