// Common
import React, { FunctionComponent, ReactElement } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { format } from 'date-fns'

// Types
import { Feed as FeedModel } from 'models/feed.model'

// Components
import Image from 'components/image'

// Hooks
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AlbumCardComponent from './AlbumCard'
import EventCardComponent from './EventCard'
import UserCardComponent from './UserCard'
import DefaultFeedCardComponent from './Defaultcard'
import PhotoCardComponent from './PhotoCard'
import PostCardComponent from './PostCard'
import TrackCardComponent from './TrackCard'

interface IComponentProps {
  feed: FeedModel;
  className?: string;
}

const entityComponents = {
  Album: AlbumCardComponent,
  Event: EventCardComponent,
  Photo: PhotoCardComponent,
  Post: PostCardComponent,
  Track: TrackCardComponent,
  User: UserCardComponent,
}

export const FeedCardComponent: FunctionComponent<IComponentProps> = ({
  feed,
  className
}): ReactElement => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const handleOpen = useCallback(() => {
    switch (feed.entityType.toString()) {
    case 'Event':
      navigate(`/events/${feed.id}`);
      break;
    case 'Post':
      navigate(`/blog/my/${feed.id}`);
      break;
    case 'Album':
      navigate(`/media/gallery/${feed.id}`);
      break;
    default:
      navigate(`/home/feed/${feed.entityId}`);
    }
  }, [navigate, feed]);

  const Component = entityComponents[feed.entityType]

  if (!Component) return <DefaultFeedCardComponent feed={ feed } />

  return (
    <div
      className={ cn( styles['feed'], className, styles[`feed-${feed.entityType?.toLowerCase()}`] ) }
      onClick={ handleOpen }
    >
      <Component
        feed={ feed }
        className={ feed.entityType }
      />
    </div>
  )
}

export default FeedCardComponent
