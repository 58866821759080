// Common
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react'

import { useFeedSearchFilters } from '../headers/contexts/FeedSearchFiltersContext'
import { useFeedFilters } from '../headers/contexts/FeedFiltersContext'

// Hooks
import { useRef } from 'react'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { FeedsSearchByParamsService } from 'services/feeds-search-by-params.service'

// Components
import FeedCard from 'containers/Authenticated/pages/home/feedCard'

interface IComponentProps {}

const ListComponent: FunctionComponent<IComponentProps> = (): ReactElement => {
  const feedsSearchByParamsService = useInject(FeedsSearchByParamsService)
  const feeds = useSubscribeValue(feedsSearchByParamsService.items, [])

  const { feedFilters } = useFeedFilters()
  const { searchQuery } = useFeedSearchFilters()

  useEffect(() => {
    return () => feedsSearchByParamsService.clear()
  }, [feedsSearchByParamsService])

  const loadMore = useCallback(() => {
    feedsSearchByParamsService.requestMany({
      perPage: 25,
      search: searchQuery,
      entityTypes: feedFilters,
    })
  }, [feedsSearchByParamsService, searchQuery, feedFilters])

  useEffect(() => {
    feedsSearchByParamsService.clear()
    loadMore()
  }, [feedFilters, searchQuery])

  const scrollContainerRef = useRef(document.getElementById('root'))

  useInfiniteScroll2({ containerRef: scrollContainerRef, loadMore })

  return (
    <>
      { feeds.map((feed, index) => (
        <FeedCard feed={ feed } key={ index } />
      )) }
    </>
  )
}

export default ListComponent

