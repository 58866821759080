import { useEffect, useRef, useState } from 'react'
import OneSignal from 'react-onesignal'
import toast from 'react-hot-toast'
import { useSelector } from '../redux/store'

const PERMISSION_DEFAULT = 'default'
const PERMISSION_GRANTED = 'granted'
const PERMISSION_DENIED = 'denied'

const usePushNotification = () => {
  const mounted = useRef(false)
  const initialStep = useRef(true)
  const [pushPermission, setPushPermission] = useState <typeof PERMISSION_DEFAULT | typeof PERMISSION_GRANTED | typeof PERMISSION_DENIED>(PERMISSION_DEFAULT)
  const profile = useSelector(({ profile: { profile }}) => profile.data)

  useEffect(() => {
    if (mounted.current) { return }
    mounted.current = true

    process.env.REACT_APP_ONE_SIGNAL_APP_ID &&
    OneSignal
    .init({ appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID, allowLocalhostAsSecureOrigin: true })
    .then(() => {
      OneSignal.getNotificationPermission()
      .then((permission) => setPushPermission(permission))

      OneSignal
        .on('notificationPermissionChange', ({ to }) => setPushPermission(to))

      OneSignal.setExternalUserId(profile.email + '-' + profile.id.toString())
    })

  }, [profile])

  useEffect(() => {
    if (initialStep.current) { return }
    initialStep.current = false

    if (pushPermission !== PERMISSION_GRANTED) {
      toast.error('User denied Notifications123', { position: 'bottom-right' })
    } else {
      OneSignal.registerForPushNotifications()
      OneSignal.setSubscription(true)
    }
  }, [pushPermission])
  return {}
}

export default usePushNotification
