// Common
import React, { FunctionComponent, ReactElement, useMemo, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from 'classnames';
import styles from "../settings.module.scss";

// Components
import Input from 'components/input'
import CheckBox from "../../../../../components/checkBox/checkBox";

const SettingsPerformerGeneralInfo: FunctionComponent = (): ReactElement => {
  const { control } = useFormContext()
  const [t] = useTranslation();
  const [inputRef, setInputRef] = useState(null);

  const inputHeight = useMemo(() => inputRef?.offsetHeight, [inputRef])

  return (
    <div className={ styles['settings-group'] }>
      <div className={ styles['settings-group-row'] }>
        <Controller
          name="nickName"
          control={control}
          render={({field}) => (
            <Input
              {...field}
              className={ styles['settings-group-row-item'] }
              control={ control }
              name='nickName'
              title={ t('titles.user_name') }
              placeholder={ t('placeholders.enter_user_name') }
              theme='dark'
            />
          )}
        />
        {/*<Controller*/}
        {/*  name="typeOfUser"*/}
        {/*  control={control}*/}
        {/*  render={({field}) => (*/}
        {/*    <Input*/}
        {/*      {...field}*/}
        {/*      className={ styles['settings-group-row-item'] }*/}
        {/*      control={ control }*/}
        {/*      name='typeOfUser'*/}
        {/*      title={ t('titles.type_of_performer') }*/}
        {/*      placeholder={ t('placeholders.enter_type_of_performer') }*/}
        {/*      theme='dark'*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
        <Controller
          name="profileName"
          control={control}
          render={({field}) => (
            <Input
              {...field}
              className={ styles['settings-group-row-item'] }
              control={ control }
              name='profileName'
              title={ t('titles.performer_name') }
              placeholder={ t('placeholders.enter_performer_name') }
              theme='dark'
            />
          )}
        />
      </div>

      <div className={ styles['settings-group-row'] }>
        {/*<Controller*/}
        {/*  name="locationCountry"*/}
        {/*  control={control}*/}
        {/*  render={({field}) => (*/}
        {/*    <Input*/}
        {/*      {...field}*/}
        {/*      className={ styles['settings-group-row-item'] }*/}
        {/*      control={ control }*/}
        {/*      name='locationCountry'*/}
        {/*      title={ t('titles.location_country') }*/}
        {/*      placeholder={ t('placeholders.enter_location_country') }*/}
        {/*      theme='dark'*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}


        {/*<Controller*/}
        {/*  name="locationCity"*/}
        {/*  control={control}*/}
        {/*  render={({field}) => (*/}
        {/*    <Input*/}
        {/*      {...field}*/}
        {/*      className={ styles['settings-group-row-item'] }*/}
        {/*      control={ control }*/}
        {/*      name='locationCity'*/}
        {/*      title={ t('titles.city') }*/}
        {/*      placeholder={ t('placeholders.enter_city') }*/}
        {/*      theme='dark'*/}
        {/*      ref={ setInputRef }*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}

        <div
          className={ cn(
            styles['settings-group-row-item'],
            styles['multiple']
          ) }
        >
          {/*<Controller*/}
          {/*  name="showCurrentLocation"*/}
          {/*  control={control}*/}
          {/*  render={({field}) => (*/}
          {/*    <CheckBox*/}
          {/*      {...field}*/}
          {/*      style={ { transform: `translateY(-${ inputHeight / 4 }px)` } }*/}
          {/*      name='showCurrentLocation'*/}
          {/*      label={ t('labels.show_current_location') }*/}
          {/*      control={ control }*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  )
}

export default SettingsPerformerGeneralInfo;
