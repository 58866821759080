import { ActionType, ActionTypes, Filters, Service } from 'types/reduxCRUD'
import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { request } from 'apiServices/http.service'
import toast from 'react-hot-toast'
import { isEqual } from 'lodash'

function resolvePath(path, obj = {}, separator= '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev?.[curr], obj)
}

export default function generateCRUDSaga<Entity, F extends Filters>({
  at,
  service,
  actions,
  entityConstructor,
  reducerName,
  apiItemsKey,
  apiItemKey,
  entityName,
  paginationStrategy
}: {
  at: ActionTypes,
  service: Service<Entity>,
  actions,
  entityConstructor: (params: unknown) => Entity,
  reducerName: string,
  apiItemsKey: string,
  apiItemKey: string,
  entityName: string,
  paginationStrategy: 'infinite-scroll' | 'pages' | 'all'
}) {
  function* read({ payload: { filters }}: { type: string, payload: { filters: F }}) {
    yield put(actions.readPending())

    const currentState = yield select((state) => resolvePath(reducerName, state))

    let { items: currentItems } = currentState

    if (paginationStrategy === 'infinite-scroll' || paginationStrategy === 'all') {
      filters.page = currentState.currentPage + 1
    }

    if (
      paginationStrategy === 'infinite-scroll' &&
      !isEqual({ ...currentState.filters, page: -1 }, { ...filters, page: -1 })
    ) {
      currentItems = []
      filters.page = 1
    }

    const { response, error } = yield call(request, service.getItems(filters))

    if (response) {
      const items = (response?.[apiItemsKey] || []).map(entityConstructor)

      if (paginationStrategy === 'infinite-scroll' || paginationStrategy === 'all') {
        yield put(actions.readSucceeded({
          items: [...currentItems, ...items],
          currentPage: filters.page,
          totalCount: response?.count || 0,
          hasMore: filters.page * filters.perPage < response?.count,
          filters
        }))
      } else if (paginationStrategy === 'pages') {
        const newItems = [...currentItems]

        if (currentItems?.length !== response?.count) {
          newItems.length = response?.count
        }

        for (let i = 0; i < filters.perPage; i++) {
          newItems[filters.page - 1 + i] = items[i]
        }

        yield put(actions.readSucceeded({
          items: newItems,
          totalCount: response?.count || 0,
          filters
        }))
      }
    } else {
      toast.error(
        error?.errors?.message ||
        error?.message?.map?.(({ constraints }) => Object.values(constraints).join(', ') ).join(', ') ||
        'Something went wrong.',
        { position: 'bottom-right' }
      )

      yield put(actions.readFailed(error))
    }
  }

  function* readSucceeded() {
    if (paginationStrategy === 'all') {
      const state = yield select((state) => resolvePath(reducerName, state))

      if (state.hasMore) {
        yield put(actions.read(state.filters))
      }
    }
  }

  function* readOne({ payload: { id }}: { type: string, payload: { id: string | number }}) {
    yield put(actions.readOnePending())

    const { response, error } = yield call(request, service.getItem(id))

    if (response) {
      const item = entityConstructor(response?.[apiItemKey])
      yield put(actions.readOneSucceeded({ item }))
    } else {
      toast.error(error?.errors?.message || 'Something went wrong.', { position: 'bottom-right' })

      yield put(actions.readOneFailed(error))
    }
  }

  function* upsert({ payload: { entity }}: { type: string, payload: { entity: Entity }}) {
    yield put(actions.upsertPending())

    const { response, error } = yield call(request, service.upsertItem(entity))

    if (response) {
      yield put(actions.upsertSucceeded())
    } else {
      toast.error(
        error?.errors?.message ||
        error?.error ||
        error?.message?.map?.(({ constraints }) => Object.values(constraints).join(', ') ).join(', ') ||
        'Something went wrong.',
        { position: 'bottom-right' }
      )

      yield put(actions.upsertFailed(error))
    }
  }

  function* deleteItem({ payload: { entity }}: { type: string, payload: { entity: Entity }}) {
    yield put(actions.deletePending())

    const { response, error } = yield call(request, service.deleteItem(entity))

    if (response) {
      // yield put(actions.deleteSucceeded())
      yield put(actions.clear())
      toast.success(`${ entityName } deleted.`, { position: 'bottom-right' })
    } else {
      toast.error(error?.errors?.message || 'Something went wrong.', { position: 'bottom-right' })

      yield put(actions.deleteFailed(error))
    }
  }

  return function* rootSaga() {
    yield all([
      yield takeLatest(at.READ, read),
      yield takeLatest(at.READ_ONE, readOne),
      yield takeEvery(at.UPSERT, upsert),
      yield takeEvery(at.DELETE, deleteItem),
      yield takeEvery(at.READ_SUCCEEDED, readSucceeded),
    ])
  }
}
