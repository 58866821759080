import React, { createContext, useContext, useState, useMemo, ReactNode, FunctionComponent } from 'react'

interface FeedFilterContextType {
  feedFilters: string[];
  setFeedFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const FeedFiltersContext = createContext<FeedFilterContextType | undefined>(undefined)

// Custom Hook for consuming context
export const useFeedFilters = () => {
  const context = useContext(FeedFiltersContext)
  if (!context) {
    throw new Error('useFeedFilters must be used within a FeedFiltersProvider')
  }
  return context
}

export const FeedFiltersProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [feedFilters, setFeedFilters] = useState<string[]>([])

  const value = useMemo(() => ({ feedFilters, setFeedFilters }), [feedFilters])

  return <FeedFiltersContext.Provider value={ value }>{ children }</FeedFiltersContext.Provider>
}
