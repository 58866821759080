// Common
import { put, all, call, takeLatest } from 'redux-saga/effects'

// Actions
import {
  REQUEST_PROFILE, REQUEST_UPDATE_PROFILE, REQUEST_UPDATE_PROFILE_IMAGE,
  receivedProfile, requestProfileFiled, requestUpdateProfileFailed,
} from '../actions/profile.action'
import { setSpinnerState } from '../actions/spinner.action'
import profileLocationsActions from '../actions/profileLocations.action-creators'

// Services
import { getProfile, updateProfile, updateProfileImage } from '../../apiServices/profile.service'
import { request } from '../../apiServices/http.service'

// Types
import { User } from '../../models/user.model'
import { ProfileImage } from '../../types/profileImage'

export function* requestProfile() {
  yield put(setSpinnerState(true))

  const { response, error } = yield call(request, getProfile())

  if (response) {
    yield put(receivedProfile(new User(response)))
  } else {
    yield put(requestProfileFiled(error))
  }

  yield put(setSpinnerState(false))
  return response
}

function* requestUpdateProfile({ payload: user }: { type: string, payload: Partial<User> }) {

  const { response, error } = yield call(request, updateProfile(user))

  if ('locations' in user) {
    yield all(user.locations.map((location) => put(profileLocationsActions.upsert(location))))
  }

  if (response) {
    yield put(receivedProfile(new User(response)))
  } else {
    yield put(requestUpdateProfileFailed(error))
  }
}

function* requestUpdateProfileImage({ payload: { image, type }}: { type: string, payload: { image: File, type: ProfileImage } }) {
  const { response, error } = yield call(request, updateProfileImage(image, type))

  if (response) {
    console.info(image)
    console.info(response)
  } else {
    console.error(error)
  }
}

export default function* rootSaga() {
  yield all([
      yield takeLatest(REQUEST_PROFILE, requestProfile),
      yield takeLatest(REQUEST_UPDATE_PROFILE, requestUpdateProfile),
      yield takeLatest(REQUEST_UPDATE_PROFILE_IMAGE, requestUpdateProfileImage)
  ])
}
