// Common
import React, { FunctionComponent, ReactElement } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { format } from 'date-fns'

// Components
import Image from 'components/image'

// Types
import { Feed as FeedModel } from 'models/feed.model'

interface IComponentProps {
  feed: FeedModel;
  className?: string;
  showFullComponent?: boolean;
}

export const EventCardComponent: FunctionComponent<IComponentProps> = ({ feed,
                                                                         className,
                                                                         showFullComponent
                                                                       }): ReactElement => {
  return (
    <div className={ cn( styles['feed-data'], className, styles[`feed-data-${feed.entityType?.toLowerCase()}`] )  }>
      <>
        <Image
          className={ styles['feed-image-event'] }
          src={ feed.imageUrl }
        />
      </>
      <div className={ styles['feed-data-container'] }>
        <p className={styles['feed-data-container-inline']}>
          <p className={ styles['feed-data-container-date'] }>
            { feed?.from && format(feed?.from, 'dd.LL yyyy') }
          </p>
          <p className={ styles['feed-data-container-location'] }>{feed?.location?.city}</p>
        </p>

        <p className={ styles['feed-data-container-title'] }>{ feed?.title } ({ feed.entityType })</p>

        <p className={ styles['feed-data-container-info'] }>{ 'Red bird Club' }</p>
      </div>
    </div>
  )
}

export default EventCardComponent
