import { Service } from '../types/reduxCRUD'
import { RequestParams } from './models/types'
import { hostURL } from './http.service'

// Models
import { Feed, FeedsFilters } from 'models/feed.model'

export const feedsService: Service<Feed> = {
  getItems(filters: FeedsFilters): RequestParams {
    const url = new URL(`${ hostURL }/feed`)

    return {
      url,
      params: {
        page: filters.page,
        per_page: filters.perPage,
        search: filters.search,
        entityTypes: filters.entityTypes,
      }
    }
  },
  getItem(): RequestParams {
    throw new Error('API not available')
  },
  upsertItem(): RequestParams {
    throw new Error('API not available')
  },
  deleteItem(): RequestParams {
    throw new Error('API not available')
  }
}

export default feedsService
