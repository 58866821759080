import React, { FunctionComponent, ReactElement, useMemo } from 'react'
import styles from './home.module.scss'
import { Outlet, Routes, Route, useSearchParams, useParams } from 'react-router-dom'

// Components
import Delimiter from '../../components/delimiter/delimiter'

// Services
import { FeedSearchFiltersProvider } from './headers/contexts/FeedSearchFiltersContext'
import { FeedFiltersProvider } from './headers/contexts/FeedFiltersContext'
import FeedListHeader  from './headers/FeedListHeader'
import FeedSearchHeader from './headers/FeedSearchHeader'

const Home: FunctionComponent = (): ReactElement => {
  const { userId } = useParams()
  const [searchParams] = useSearchParams()
  const feeds = useMemo(
    () => ({
      userId,
    }),
    [searchParams, userId]
  )

  return (
    <FeedSearchFiltersProvider>
      <FeedFiltersProvider>
        <Routes>
          <Route
            index={ true }
            element={
              <>
                <FeedSearchHeader />
                <Delimiter className={ styles['delimiter'] } />
                <FeedListHeader />
                <Delimiter className={ styles['delimiter'] } />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <div className={ styles['indent'] } />
              </>
            }
          />
        </Routes>

        <Outlet context={ feeds } />
      </FeedFiltersProvider>
    </FeedSearchFiltersProvider>
  )
}

export default Home

