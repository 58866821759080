// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './settings.module.scss';

// Hooks
import { useCallback, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useSelector } from 'redux/store'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, useFormState } from 'react-hook-form'

// Actions
import { requestUpdateProfile } from 'redux/actions/profile.action'
import { requestGenres } from 'redux/actions/genres.action'
import profileLocationsActions from 'redux/actions/profileLocations.action-creators'

// Types
import { Location } from 'models/location.model'
import { Role } from 'types/role'

// Components
import Button from 'components/button'
import SettingsBusiness from './business/settings.business'
import SettingsPerformer from './performer/settings.performer'
import SettingsFun from './fun/settings.fun'

const Settings: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const { profile: { data: user }, locations: { items: locations }} = useSelector(({ profile }) => profile)

  const formMethods = useForm({ defaultValues: {
      ...user.asForm(),
      locations: locations.length ? locations : [Location.createItem()]
    }});
  const { dirtyFields } = useFormState({ control: formMethods.control })

  const submit = useCallback((data) => {
    const updatedFields = Object.keys(dirtyFields)
      .reduce((memo, updatedProp) => ({ ...memo, [updatedProp]: data[updatedProp] }), {})

    if (Object.keys(updatedFields).length > 0) { dispatch(requestUpdateProfile(updatedFields)); }
  }, [dispatch, dirtyFields]);

  useEffect(() => {
    // dispatch(profileLocationsActions.read({}))

    return () => {
      dispatch(profileLocationsActions.clear())
    }
  }, [dispatch])

  useEffect(() => {
    formMethods.setValue('locations', locations)
  }, [locations, formMethods])

  return (
    <FormProvider { ...formMethods }>
      <form
        className={ styles['settings'] }
        onSubmit={ formMethods.handleSubmit(submit) }
      >
        { user.role === Role.BUSINESS && <SettingsBusiness /> }
        { user.role === Role.PERFORMER && <SettingsPerformer /> }
        { user.role === Role.REGULAR && <SettingsFun /> }

        <div className={ styles['settings-actions'] }>
          <Button
            appearance='gold'
            type='submit'
          >
            { t('buttons.save') }
          </Button>
        </div>

      </form>
    </FormProvider>
  )
}

export default Settings;
